import React, { useEffect, useState } from "react";
import "./Orders.css";
import DataTable from "react-data-table-component";
import { PopupModal } from "../Components";
import AlertDeletePopupModal from "../Components/PopupModal/AlertDeletePopupModal";
import axiosConfig from "../Service/axiosConfig";
import { BiRupee } from "react-icons/bi";
import Select from 'react-select';
import { TbSearch } from "react-icons/tb";
import {
  MdOutlineLocationOn,
  MdPrint,
  MdOutlineEditNote,
  MdOutlineLocalPhone,
  MdOutlineMapsHomeWork,
} from "react-icons/md";
import { RiSendPlaneFill } from "react-icons/ri";
import { GrGallery } from "react-icons/gr";
import { IoCloseCircleOutline } from "react-icons/io5";
import tableCustomStyles from "../Components/PopupModal/tableCustomStyles";
import { useReloadContext } from "../Components/DashboardLayout/DashboardLayout";
import useDebouncedValue from "../Utils/Debounce";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ShippingInstructions } from "./ShippingInstructions";
const Orders = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user_data"));
  const [stores, setStores] = useState([]);
  const [OrderDetail, SetOrderDetails] = useState([]);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebouncedValue(search, 500);
  const [popupType, setpopupType] = useState("");
  const [OrderId, SetOrderId] = useState();
  const [oreders, setOrders] = useState({});
  const [OrderBiId, SetOrderById] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  const [Active, setActive] = useState("Placed");
  const [Invoice, setInvoice] = useState({});
  const [loadingButton, setLoadingButton] = useState(false);
  const { reload, setReload, formatDate } = useReloadContext();
  const [DataCount, setDataCount] = useState("");
  const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("");
  const [RowId, SetRowId] = useState();
  const [AlertDeletePopupType1, SetAlertDeletePopupType1] = useState("");
  const [RowId1, SetRowId1] = useState();
  const [message, setMessage] = useState(null);
  const [discountTotals, setDiscountTotals] = useState(null);
  const [page, setPage] = useState(1);
  const [TotalPages, setTotalPages] = useState(0);
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  const formattedDate = `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
  let store_id = JSON.parse(localStorage.getItem("Store"))?.id;
  const [selectedStore, setSelectedStore] = useState({ value: store_id || "" });
  console.log(store_id, "store id");
  const CountDisplay = async () => {
    try {
      const responce = await axiosConfig.get(
        `accounts/today_order_count/?store=${selectedStore?.value
        }&start_date=${formattedDate}&end_date=${formattedDate}`
      );
      setDataCount({
        ...DataCount,
        Cart: responce.data.Cart,
        Rejected: responce.data.Rejected,
        Delivered: responce.data.Delivered,
      });
    } catch (error) {
      console.log(error);
    }
  };
  function calculateDiscountTotals(couponApplied) {
    return couponApplied.reduce(
      (totals, item) => {
        if (item.discount_type === "BogoDiscount") {
          totals.bogo_discount += parseFloat(item.coupon_discount);
        } else if (item.discount_type === "DiscountCoupon") {
          totals.product_discount += parseFloat(item.coupon_discount);
        }
        return totals;
      },
      { bogo_discount: 0, product_discount: 0 }
    );
  }
  const GetStores = async () => {
    if (stores.length > 0) return
    try {
      const responce = await axiosConfig.get(`/stores/stores/?page_size=1000`)
      console.log(responce)
      setStores(responce.data.results)
    }
    catch (error) {
      console.log(error)
    }
  }
  const handleStoreChange = (selectedOption) => {
    setSelectedStore({ value: selectedOption?.value || "" });
  };
  const OrderDetails = async (status) => {
    setLoading(true);
    const date_to_filter =
      status === "Placed" || status === "Accepted" || status === "Shipped"
        ? ""
        : formattedDate;
    const response = await axiosConfig.get(
      `/accounts/orders/?user=&orderstatus=${status}&order_date_after=${date_to_filter}&order_date_before=${date_to_filter}&stores=${selectedStore.value
      }`
    );
    setDataCount((prev) => ({
      ...prev,
      [status]: response.data.count,
    }));
    setLoading(false);
    // CountDisplay()
    console.log(response, "response");
    // let updata = { ...count };
    if (response.data.results.length > 0) {
      HandleOrder(
        response.data.results[0].id,
        0,
        response.data.results[0].total_quantity,
        response.data.results[0].orderstatus,
        response.data.results[0].delivery_charges,
        response.data.results[0].packing_charges,
        response.data.results[0].discount_amount,
        response.data.results[0].total_amount,
        response.data.results[0].net_amount,
        response.data.results[0].user_name,
        response.data.results[0].addresses,
        response.data.results[0].delivery_type,
        response.data.results[0].store,
        response.data.results[0].invoice,
        response.data.results[0].payment_mode,
        response.data.results[0]
      );
      setOrderInfo(response[0]);
    } else {
      if (status === "Placed") {
        // updata["Placed"] = 0;
        SetOrderById([]);
        setOrders();
      }
      if (status === "Accepted") {
        // updata["Accepted"] = 0;
        SetOrderById([]);
        setOrders();
      }
      if (status === "Shipped") {
        // updata["Shipped"] = 0;
        SetOrderById([]);
        setOrders();
      }
      if (status === "Delivered") {
        // updata["Delivered"] = 0;
        SetOrderById([]);
        setOrders();
      }
      if (status === "Rejected") {
        SetOrderById([]);
        setOrders();
      }
      // if (status === "Packed") {
      //   updata["Packed"] = 0;
      //   SetOrderById([])
      //   setOrders()
      // }
    }
    SetOrderDetails(response.data.results);
    setTotalPages(Math.ceil(response.data.count / 10));
  };
  const OrderStatusTotal = async () => {
    setLoading(true);
    try {
      const confirmedResponse = await axiosConfig.get(
        `/accounts/orders/?user=&orderstatus=Placed&order_date_after=&order_date_before=&stores=${selectedStore?.value
        }`
      );
      const acceptedResponse = await axiosConfig.get(
        `/accounts/orders/?user=&orderstatus=Accepted&order_date_after=&order_date_before=&stores=${selectedStore?.value
        }`
      );
      const shippedResponse = await axiosConfig.get(
        `/accounts/orders/?user=&orderstatus=Shipped&order_date_after=&order_date_before=&stores=${selectedStore?.value
        }`
      );
      if (confirmedResponse.data.results.length > 0) {
        const firstOrder = confirmedResponse.data.results[0];
        HandleOrder(
          firstOrder.id,
          0,
          firstOrder.total_quantity,
          firstOrder.orderstatus,
          firstOrder.delivery_charges,
          firstOrder.packing_charges,
          firstOrder.discount_amount,
          firstOrder.total_amount,
          firstOrder.net_amount,
          firstOrder.user_name,
          firstOrder.addresses,
          firstOrder.delivery_type,
          firstOrder.store,
          firstOrder.invoice,
          firstOrder.payment_mode,
          firstOrder
        );
        // HandleInvoice(firstOrder.id);
        setOrderInfo(confirmedResponse.data.results[0]);
        setTotalPages(Math.ceil(confirmedResponse.data.count / 10));
      }
      SetOrderDetails(confirmedResponse.data.results);
      setTotalPages(Math.ceil(confirmedResponse.data.count / 10));
      setDataCount((prev) => ({
        ...prev,
        Placed: confirmedResponse.data.count,
        Accepted: acceptedResponse.data.count,
        Shipped: shippedResponse.data.count,
      }));
      Handlestatus("Placed")
      setLoading(false);
      setReload(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching order details:", error);
    }
  };

  const handleInfiniteScroll = async (e) => {
    try {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      console.log("scrollTop:", scrollTop);
      console.log("clientHeight:", clientHeight);
      console.log("scrollHeight:", scrollHeight);
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        console.log("TotalPages:", TotalPages, "page:", page);
        if (page < TotalPages) {
          console.log(TotalPages);
          setLoading2(true);
          setPage(page + 1);
          let resp = await axiosConfig.get(
            `/accounts/orders/?user=&orderstatus=Placed&page=${page + 1}&order_date_after=&order_date_before=&stores=${selectedStore?.value
            }`
          );
          SetOrderDetails((Data) => [...Data, ...resp.data.results]);
          setDataCount((prev) => ({
            ...prev,
            Placed: resp.data.count,
          }));
          setLoading2(false);
        } else {
          setLoading2(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };

  const timeConvert = (date) => {
    const givenDateTime = new Date(date);
    let hours = givenDateTime.getHours();
    const minutes = givenDateTime.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours %= 12;
    hours = hours || 12;
    const newTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    return newTime;
  };
  const HandleSearch = (e) => {
    const { name, value } = e.target;
    setSearch(value);
  };
  const columns = [
    {
      name: "Item",
      selector: "",
      cell: (row) => (
        <>
          <div className="itm-table">
            <div className="table-image-main">
              <img
                src={row.product.image && row.product.image.image}
                alt={row.product.name}
                className="table-itm-img"
              />
            </div>
            <div className="image-name-order">
              <div>{row.product.name}</div>
              <div>{row.product.weight_in_kg}</div>
              <div className="discount-comment">{row.discount_comment}</div>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Qty",
      selector: "quantity",
      cell: (row) => row.quantity,
    },
    {
      name: "Price",
      selector: "price",
      cell: (row) => {
        if (row.product && row.price) {
          const offerPrice = parseFloat(row.product.offer_price);
          return offerPrice > 0 ? (
            <div>
              <strike>{row.product.price}</strike> {offerPrice.toFixed(2)}
            </div>
          ) : (
            row.product.price
          );
        } else {
          return "N/A";
        }
      },
    },
    {
      name: "Amount",
      selector: "price",
      cell: (row) => row.price * row.quantity,
    },
  ];
  const HandleOrder = async (
    id,
    i,
    qty,
    status,
    delivery_charges,
    packing_charges,
    discount_amount,
    total_amount,
    net_amount,
    user_name,
    addresses,
    delivery_type,
    store,
    invoice,
    payment_mode,
    order
  ) => {
    setLoading(true);
    console.log(order);
    const responce1 = await axiosConfig.get(
      `/accounts/order-details/?order=${id}&page_size=1000`
    );
    SetOrderById(responce1.data.results);
    SetOrderId(i);
    console.log(order.delivery_charges_offer, order.packing_charges_offer);
    setOrderInfo(order);
    setOrders({
      order_id: id,
      total_items: qty,
      status: status,
      delivery_charges: delivery_charges,
      packing_charges: packing_charges,
      discount_amount: discount_amount,
      total_amount: total_amount,
      net_amount: net_amount,
      user_name: user_name,
      addresses: addresses,
      delivery_type: delivery_type,
      store: store,
      invoice: invoice,
      payment_mode: payment_mode,
      delivery_charges_offer: order.delivery_charges_offer
        ? order.delivery_charges_offer
        : 0,
      packing_charges_offer: order.packing_charges_offer
        ? order.packing_charges_offer
        : 0,
      delivery_agent: order.delivery_agent ? order.delivery_agent : "",
      order: order,
    });
    setLoading(false);
    setInvoice(invoice);
    let totals = calculateDiscountTotals(order?.coupon_code);
    setDiscountTotals(totals);
  };
  const handlePrint = async () => {
    try {
      if (Invoice) {
        window.open(Invoice.pdf_path, "_blank");
      } else {
        alert("invoice not available");
      }
    } catch (error) {
      console.log("Error getting invoice");
    }
  };
  const HandleDelete = async () => {
    let resp = await axiosConfig.post(
      `/accounts/orders/${RowId}/order_status_update/`,
      { orderstatus: "Rejected" }
    );
    setMessage("Order Cancelled Successfully !");
    setpopupType("success");
    setLoadingButton(false);
    SetAlertDeletePopupType("error");
    CountDisplay();
    OderSCount(["Rejected", Active]);
  };
  const CancelAlerrt = () => {
    setLoadingButton(false);
    setMessage(null);
    SetAlertDeletePopupType("error");
  };
  const CancelAlerrt1 = () => {
    setLoadingButton(false);
    setMessage(null);
    SetAlertDeletePopupType1("error");
  };
  const HandleAccept = async () => {
    console.log(RowId1);
    let resp = await axiosConfig.post(
      `/accounts/orders/${RowId1}/order_status_update/`,
      { orderstatus: "Accepted" }
    );
    setMessage("Order Accepted Successfully!");
    setpopupType("success");
    setActive("Accepted");
    SetAlertDeletePopupType1("error");
    OderSCount(["Accepted", "Placed"]);
    setLoadingButton(false);
    CountDisplay();
  };
  const Handlestatus = (status) => {
    if (status === "Placed") {
      setActive("Placed");
      OrderDetails("Placed");
    }
    if (status === "Accepted") {
      setActive("Accepted");
      OrderDetails("Accepted");
    }
    if (status === "Shipped") {
      setActive("Shipped");
      OrderDetails("Shipped");
    }
    if (status === "Delivered") {
      setActive("Delivered");
      OrderDetails("Delivered");
    }
    if (status === "Rejected") {
      setActive("Rejected");
      OrderDetails("Rejected");
    }
    if (status === "Packed") {
      setActive("Packed");
      OrderDetails("Packed");
    }
  };
  const OderSCount = async (statuses) => {
    setLoading(true);
    try {
      const responses = await Promise.all(
        statuses.map((status) => {
          const date_to_filter =
            status === "Placed" || status === "Accepted" || status === "Shipped"
              ? ""
              : formattedDate;
          return axiosConfig
            .get(
              `/accounts/orders/?user=&orderstatus=${status}&order_date_after=${date_to_filter}&order_date_before=${date_to_filter}&stores=${selectedStore?.value}`
            )
            .then((response) => ({
              ...response.data,  // Spread other response data
              status,            // Add status
              count: response.data.count, // Add count
            }))
            .catch((error) => {
              console.error(`Error fetching status ${status}:`, error);
              return { status, count: 0 }; // Return default if error occurs
            });
        })
      );

      // Once all the promises have resolved, update the state
      responses.forEach(({ status, count }) => {
        setDataCount((prev) => ({
          ...prev,
          [status]: count,
        }));
      });

      setLoading(false);
      if (responses.length > 0) {
        SetOrderDetails(responses[0].data.results);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching order details:", error);
    }
  };
  const Handlebutttons = async (status) => {
    console.log(status);
    setLoadingButton(true);
    if (status === "Done") {
      SetRowId1(oreders.order_id);
      // SetAlertDeletePopupType1("success")
      // setMessage("Are you sure you want to Accept this order?")
      if (
        JSON.parse(localStorage.getItem("site_data")).ecommerce_type ===
        "Hyperlocal"
      ) {
        setShow(true);
      } else {
        let resp = await axiosConfig.post(
          `/accounts/orders/${oreders.order_id}/order_status_update/`,
          { orderstatus: "Accepted" }
        );
        setActive("Accepted");
        OderSCount(["Accepted", "Placed"]);
        CountDisplay();
        setLoadingButton(false);
      }
    } else if (status === "Shipped") {
      let resp = await axiosConfig.post(
        `/accounts/orders/${oreders.order_id}/order_status_update/`,
        { orderstatus: "Shipped" }
      );
      setActive("Shipped");
      OderSCount(["Shipped", "Accepted"]);
      CountDisplay();
      setLoadingButton(false);
    } else if (status === "Delivered") {
      let resp = await axiosConfig.post(
        `/accounts/orders/${oreders.order_id}/order_status_update/`,
        { orderstatus: "Delivered" }
      );
      setActive("Delivered");
      OderSCount(["Delivered", "Shipped"]);
      CountDisplay();
      setLoadingButton(false);
    } else if (status === "Reject") {
      SetRowId(oreders.order_id);
      setMessage("Are you sure you want to cancel this order?");
      SetAlertDeletePopupType("success");
    } else if (status === "Modify") {
      console.log("modify", orderInfo);
      navigate(`/dashboard/orders/${oreders.order_id}/modify`, {
        state: { order_data: orderInfo, order_address: oreders },
      });
    }
  };
  const HandleButtons = () => {
    return (
      <div className="rowAligned gap-20 flex-1">
        <div className="flex-1 column-h-71">
          <div className="rowBetweenCenter">
            <div className="rowAligned gap-10">
              <MdOutlineMapsHomeWork size={16} />
              <div className="client-address f_13_600">
                {oreders.addresses?.name}
              </div>
            </div>
            <div className="icon-container-16">
              <a
                href={`tel:+${oreders.addresses?.user.mobileno}`}
                style={{ height: "28px" }}
              >
                <MdOutlineLocalPhone size={14} color="#1b7d78" />
              </a>
            </div>
          </div>
          <div className="rowBetweenCenter">
            <div className="rowAligned gap-10">
              <MdOutlineLocationOn size={16} />
              <div className="client-address f_13_500">
                {oreders.addresses?.address}.
              </div>
            </div>
            <div className="icon-container-16">
              <RiSendPlaneFill size={14} color="#1b7d78" />
            </div>
          </div>
          <div className="rowBetweenCenter">
            <div className="f_13_400 rowAligned gap-10">
              <MdOutlineLocalPhone size={16} color="#8C8C8C" />
              <div style={{ color: "#8C8C8C" }}>
                {oreders.addresses?.user.mobileno}
              </div>
            </div>
          </div>
          <div className="rowBetweenCenter">
            <div className="payment-modee">
              {oreders.payment_mode === "cash"
                ? "Cash on delivery"
                : "Online payment"}
            </div>
            <div className="order-statuss">{oreders.status}</div>
          </div>
        </div>
      </div>
    );
  };
  const [show, setShow] = useState(false);
  // const handleUpdated = async (shipId, method) => {
  //   console.log(shipId, "shipId")
  //   let Data_to_send = {
  //     ...Data,
  //     orderstatus: "Shipped",
  //     "shipping_method": typeof (shipId) === "number" ? shipId : shipId.id,
  //   }
  //   console.log(Data_to_send, "data")
  //   try {
  //     const response = await axiosConfig.post(`/accounts/orders/${oreders.order_id}/order_status_update/`, Data_to_send, {
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     });
  //     console.log(response, "response")
  //     setData({})
  //     setOther(false)
  //     setAssign(false)
  //     setActive("Shipped")
  //     CountDisplay()
  //     OderSCount(["Shipped", "Accepted"])
  //     setLoadingButton(false)
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  // }
  useEffect(() => {
    const getSearchResults = async () => {
      try {
        const date_to_filter =
          Active === "Placed" || Active === "Accepted" || Active === "Shipped"
            ? ""
            : formattedDate;
        let resp = await axiosConfig.get(
          `/accounts/orders/?user=&orderstatus=${Active}&order_date_after=${date_to_filter}&order_date_before=${date_to_filter}&search=${debouncedSearchTerm}&stores=${selectedStore?.value
          }`
        );
        SetOrderDetails(resp.data.results);
        setDataCount((prev) => ({
          ...prev,
          [Active]: resp.data.count,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getSearchResults();
  }, [debouncedSearchTerm]);
  useEffect(() => {
    console.log(reload, "reload");
    OrderStatusTotal();
    CountDisplay();
    GetStores();
  }, [reload, selectedStore]);
  return (
    <div>
      <div className="row">
        <div className="col-3 Manage-Orders">
          <h6>Manage Orders:</h6>
        </div>
        <div className="col-9 order-header-main">
          <div className="orders-inProgress">
            <div
              className={
                Active === "Placed" ? "activeCalss-order" : "Class-order"
              }
              onClick={() => Handlestatus("Placed")}
            >
              <div>New</div>
              <div
                className={
                  Active === "Placed" ? "length-data" : "lenght-Inactive"
                }
              >
                {DataCount.Placed ? DataCount.Placed : 0}
              </div>
            </div>
            <div
              className={
                Active === "Accepted" ? "activeCalss-order" : "Class-order"
              }
              onClick={() => Handlestatus("Accepted")}
            >
              <div>Accepted</div>
              <div
                className={
                  Active === "Accepted" ? "length-data" : "lenght-Inactive"
                }
              >
                {DataCount.Accepted ? DataCount.Accepted : 0}
              </div>
            </div>
            <div
              className={
                Active === "Shipped" ? "activeCalss-order" : "Class-order"
              }
              onClick={() => Handlestatus("Shipped")}
            >
              <div>Shipped</div>
              <div
                className={
                  Active === "Shipped" ? "length-data" : "lenght-Inactive"
                }
              >
                {DataCount.Shipped ? DataCount.Shipped : 0}
              </div>
            </div>
            <div
              className={
                Active === "Delivered" ? "activeCalss-order" : "Class-order"
              }
              onClick={() => Handlestatus("Delivered")}
            >
              <div>Delivered</div>
              <div
                className={
                  Active === "Delivered" ? "length-data" : "lenght-Inactive"
                }
              >
                {DataCount.Delivered ? DataCount.Delivered : 0}
              </div>
            </div>
            <div
              className={
                Active === "Rejected" ? "activeCalss-order" : "Class-order"
              }
              onClick={() => Handlestatus("Rejected")}
            >
              <div>Rejected</div>
              <div
                className={
                  Active === "Rejected" ? "length-data" : "lenght-Inactive"
                }
              >
                {DataCount.Rejected ? DataCount.Rejected : 0}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className=" col-3 input-with-icon zero-padding">
          <span className="icon">
            {" "}
            <TbSearch />
          </span>
          <input
            className="input-control"
            type="text"
            placeholder="Search With Order Id"
            onChange={HandleSearch}
          />
        </div>
        {user.role === "Admin" &&
          <div className="store-select col-9">
            <Select
              options={stores.map(store => ({ value: store.id, label: store.name }))}
              placeholder="Select Store"
              value={stores.map(store => ({ value: store.id, label: store.name })).find(res => res.value === parseFloat(selectedStore.value))}
              onChange={(selectedOption) => handleStoreChange(selectedOption)}
              isClearable
            />
          </div>}
      </div>
      <div className="row">
        <div
          className="col-3 order-data"
          onScroll={(e) => handleInfiniteScroll(e)}
        >
          {loading ? (
            <div className="loader-container">
              <img
                src={require("../Components/FormParser/images/loader.gif")}
                alt="Loading..."
              />
            </div>
          ) : (
            OrderDetail.map((order, i) => {
              return (
                <div
                  className={
                    OrderId === i ? "ordrs-bottom active" : "ordrs-bottom"
                  }
                  key={i}
                  onClick={() =>
                    HandleOrder(
                      order.id,
                      i,
                      order.total_quantity,
                      order.orderstatus,
                      order.delivery_charges,
                      order.packing_charges,
                      order.discount_amount,
                      order.total_amount,
                      order.net_amount,
                      order.user_name,
                      order.addresses,
                      order.delivery_type,
                      order.store,
                      order.invoice,
                      order.payment_mode,
                      order
                    )
                  }
                >
                  <div className="d-flex justify-content-between ">
                    <div className="order_id">{order.id}</div>
                    <div className="order-time">
                      {order.order_date.split("T")[0]}
                    </div>
                  </div>
                  <div className="store-date">
                    <div className="store-name">
                      {order.store ? order.store.name : "add store -"}
                    </div>
                    <div>{timeConvert(order.order_date)}</div>
                  </div>
                  <div className="order-money-items">
                    <div className="f_12_400">{order.total_quantity} Items</div>
                    <div className="f_12_400">
                      <BiRupee />
                      {parseInt(order.net_amount)}
                    </div>
                  </div>
                </div>
              );
            })
          )}
          {loading2 && (
            <div className="loader-container">
              <img
                src={require("../Components/FormParser/images/loader.gif")}
                alt="Loading..."
              />
            </div>
          )}
        </div>
        {OrderBiId.length > 0 ? (
          <div className="col-9 order-act">
            <div className="row border-order">
              <div className="col-5 order-actions">{HandleButtons()}</div>
              <div className="col-4" style={{ borderRight: "1px solid #ddd" }}>
                <div className="store-details-roder">
                  <div className="f_14_500">Order Id</div>
                  <div className="w_130 ellipsis">{oreders.order_id}</div>
                </div>
                <div className="store-details-roder">
                  <div className="f_14_500">Delivery Agent</div>
                  <div className="f_14_400 w_130 rowAligned">
                    {console.log(oreders)}
                    <div className="ellipsis w_100">
                      Delivery agent -{oreders.delivery_agent?.user?.full_name}
                    </div>
                  </div>
                </div>
                <div className="store-details-roder">
                  <div className="f_14_500">Store</div>
                  <div className="f_14_400 w_130 ellipsis">
                    {oreders.store?.name}
                  </div>
                </div>
                <div className="store-details-roder">
                  <div className="f_14_500">Delivery type</div>
                  <div className="f_14_400 w_130 ellipsis">
                    {oreders?.delivery_type}
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="actions">
                  <div className="d-flex align-items-center justify-content-between">
                    <MdPrint
                      size={24}
                      onClick={() => handlePrint()}
                      style={{ cursor: "pointer" }}
                    />
                    {Active !== "Delivered" && Active !== "Rejected" && (
                      <>
                        <MdOutlineEditNote
                          size={24}
                          onClick={() => Handlebutttons("Modify")}
                          style={{ cursor: "pointer" }}
                        />
                        <IoCloseCircleOutline
                          color="red"
                          size={24}
                          onClick={() => Handlebutttons("Reject")}
                          style={{ cursor: "pointer" }}
                        />
                      </>
                    )}
                  </div>
                  <div className="rowBetweenCenter">
                    <div className="f_14_500">Expected date : </div>
                    <div className="f_14_400">
                      {
                        OrderDetail.find(
                          (item) => item.id === oreders.order_id
                        )?.expected_date_time?.split("T")[0]
                      }
                    </div>
                  </div>
                  {oreders?.delivery_type !== "Express" ? (
                    <div className="rowBetweenCenter">
                      <div className="f_14_500">Scheduled slot : </div>
                      <div className="f_14_400">
                        {
                          OrderDetail.find(
                            (item) => item.id === oreders.order_id
                          )?.schedule_slot
                        }
                      </div>
                    </div>
                  ) : (
                    <div className="rowBetweenCenter">
                      <div className="f_14_500">Expected time : </div>
                      <div className="f_14_400">
                        {OrderDetail.find(
                          (item) => item.id === oreders.order_id
                        )
                          ?.expected_date_time.split("T")[1]
                          .split(":")
                          .slice(0, 2)
                          .join(":")}
                      </div>
                    </div>
                  )}
                  {loadingButton ? (
                    <div className="text-center">
                      <img
                        src={require("../Components/FormParser/images/loader.gif")}
                        alt="Loading..."
                        className="loader-image"
                      />
                    </div>
                  ) : (
                    <>
                      {Active === "Placed" && (
                        <button
                          type="button"
                          className="btn btn-pink f_14_500"
                          onClick={() => Handlebutttons("Done")}
                        >
                          Accept Order
                        </button>
                      )}
                      {Active === "Accepted" && (
                        <button
                          type="button"
                          className="btn btn-pink f_14_500"
                          onClick={() => Handlebutttons("Shipped")}
                        >
                          Ship Order
                        </button>
                      )}
                      {Active === "Shipped" && (
                        <button
                          type="button"
                          className="btn btn-pink f_14_500"
                          onClick={() => {
                            Handlebutttons("Delivered");
                          }}
                        >
                          Deliver Order
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            {Active === "Shipped" ? (
              <div className="ordrs-tota-button">
                <button type="button" className="button-orders-image">
                  <GrGallery />{" "}
                  <span className="button-text-order">
                    Photos by QC & Partner
                  </span>
                </button>
              </div>
            ) : Active === "Delivered" ? (
              <div className="oreder-precessed">
                <div>
                  <img
                    className="success-image"
                    src={require("./images/tick.png")}
                    alt="tick"
                  />
                </div>
                <div>
                  <p>This order has been processed successfully</p>
                </div>
              </div>
            ) : Active === "Rejected" ? (
              <div className="oreder-precessed">
                <div>
                  <img
                    className="success-image"
                    src={require("./images/tick.png")}
                    alt="tick"
                  />
                </div>
                <div>
                  <p>This order has been Cancelled</p>
                </div>
              </div>
            ) : (
              <div className="ordrs-total rowBetweenCenter">
                <span>Total Items-{oreders.total_items}</span>
                <span>Payment mode : {oreders.payment_mode}</span>
              </div>
            )}
            <div className="table-responsive order-header">
              <DataTable
                columns={columns}
                data={OrderBiId}
                fixedHeader
                customStyles={tableCustomStyles}
                subHeader
                progressPending={loading}
                progressComponent={
                  <div className="loader-container">
                    <img
                      src={require("../Components/FormParser/images/loader2.gif")}
                      alt="Loading..."
                    />
                  </div>
                }
              />
            </div>
            <div className="money-data">
              <div>
                <div className="price-border">
                  <div className="details-money">
                    <div className="rowCenter">
                      <p>Item Total</p>
                      {/* {oreders.discount_amount > 0 && <p className="saved">saved {oreders.discount_amount}</p>} */}
                      {oreders.discount_amount > 0 && (
                        <p className="saved">
                          saved ₹
                          {(
                            parseFloat(oreders.discount_amount) +
                            parseFloat(discountTotals?.bogo_discount) +
                            parseFloat(discountTotals?.product_discount)
                          ).toFixed(2)}
                        </p>
                      )}
                    </div>
                    {oreders.discount_amount > 0 ? (
                      <div className="rowCenter gap-10">
                        <p>
                          <strike>
                            {parseFloat(oreders.total_amount) +
                              parseFloat(oreders.discount_amount)}
                          </strike>
                        </p>
                        <p>{parseFloat(oreders.total_amount)}</p>
                      </div>
                    ) : (
                      <p>{oreders.total_amount}</p>
                    )}
                  </div>
                  {discountTotals?.bogo_discount > 0 && (
                    <div className="rowBetweenCenter">
                      <p>Buy 1 Get 1 discount</p>
                      <p>-₹{parseFloat(discountTotals.bogo_discount)}</p>
                    </div>
                  )}
                  {discountTotals?.product_discount > 0 && (
                    <div className="rowBetweenCenter">
                      {console.log("discountTotals", discountTotals)}
                      <p>Coupon discount</p>
                      <p>
                        -₹
                        {parseFloat(discountTotals.product_discount).toFixed(2)}
                      </p>
                    </div>
                  )}
                  <div className="details-money">
                    <p>Packing Charges</p>
                    {oreders.packing_charges !== "0.00" ? (
                      <p>{oreders.packing_charges}</p>
                    ) : (
                      <>
                        <strike>{oreders.packing_charges_offer}</strike>
                        <span>Waiver</span>
                      </>
                    )}
                  </div>
                  <div className="details-money">
                    <p>Delivery Charges</p>
                    {oreders.delivery_charges !== "0.00" ? (
                      <p>{oreders.delivery_charges}</p>
                    ) : (
                      <>
                        {oreders.order.coupon_code.length > 0 &&
                          oreders.order.coupon_code[0].discount_type ===
                          "FreeDeliveryChargesCoupon" ? (
                          <></>
                        ) : (
                          <strike>{oreders.delivery_charges_offer}</strike>
                        )}
                        <span>
                          {oreders.order.coupon_code.length > 0 &&
                            oreders.order.coupon_code[0].discount_type ===
                            "FreeDeliveryChargesCoupon"
                            ? "Free Delivery"
                            : "waiver"}
                        </span>
                        {/* <strike>
                          ₹
                          {selectedOption === "DeliveryBy" ||
                          selectedOption === "Scheduled"
                            ? oreders.schedule_delivery_charges
                            : oreders.express_delivery_charges}
                        </strike> */}
                        {/* <span className="ms-2">
                          {parseFloat(discountTotals.free_delivery_charges)
                            ? "Free Delivery"
                            : "waiver"}
                        </span> */}
                      </>
                    )}
                  </div>
                  {/* <div className="details-money">
                  <p>Discount</p>
                  <p>{oreders.discount_amount}</p>
                </div> */}
                </div>
                <div className="details-money-total">
                  <p>Grand Total</p>
                  <p>{parseFloat(oreders.net_amount)}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-9 text-center">
            <div className="no-orders"></div>
            <p className="para-table-nodata">No {Active} Orders</p>
          </div>
        )}
        {popupType !== "" && (
          <PopupModal
            popupType={popupType}
            setpopupType={setpopupType}
            Message={message}
            OrderDetails={OrderDetails}
            Active={Active}
          />
        )}
        {AlertDeletePopupType === "success" && (
          <AlertDeletePopupModal
            AlertDeletePopupType={AlertDeletePopupType}
            HandleDelete={HandleDelete}
            CancelAlerrt={CancelAlerrt}
            row={RowId}
            Message={message}
          />
        )}
        {AlertDeletePopupType1 === "success" && (
          <AlertDeletePopupModal
            AlertDeletePopupType={AlertDeletePopupType1}
            HandleDelete={HandleAccept}
            CancelAlerrt={CancelAlerrt1}
            row={RowId1}
            Message={message}
          />
        )}
      </div>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
          setLoadingButton(false);
        }}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            <div>Ship order via</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ShippingInstructions
            show={show}
            setShow={setShow}
            orderId={oreders?.order_id}
            setLoadingButton={setLoadingButton}
            setActive={setActive}
            CountDisplay={CountDisplay}
            OderSCount={OderSCount}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};
export { Orders };
